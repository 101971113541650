// components/ProtectedPage.js
import React from "react";
import { useUser } from "@auth0/nextjs-auth0/client";
import LoadingState from "@/components/feedback/LoadingState";
import LoginButton from "@/components/auth/LoginButton";

const ProtectedHomepage = React.memo(({ children }) => {
	const { user, isLoading } = useUser();

	if (isLoading) {
		return <LoadingState />;
	}

	if (!user) {
		return (
			<main className="px-4 sm:px-6 py-4">
				<div className="bg-white">
					<h2 className="text-xl font-semibold mt-4 mb-4">Welcome to the Rent IQ App</h2>
					<p className="mb-6">Please sign in below</p>
					<LoginButton />
				</div>
			</main>
		);
	}

	return <>{children}</>;
});

ProtectedHomepage.displayName = "ProtectedHomepage";

export default ProtectedHomepage;
