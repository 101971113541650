// src/services/getPropertiesbyUser.js
export const getPropertiesByUser = async ({ accessToken, auth0UserId }) => {
	const apiBaseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
	try {
		const response = await fetch(`${apiBaseUrl}/api/v1/properties/by-user/${auth0UserId}`, {
			method: "GET",
			headers: { Authorization: `Bearer ${accessToken}` },
		});
		if (!response.ok) {
			if (response.status === 204 || response.headers.get("content-length") === "0") {
				// No content to parse
				throw new Error("Failed to fetch properties");
			}
			let errorData = {};
			try {
				errorData = await response.json();
			} catch (e) {
				throw new Error("Failed to fetch properties: Invalid response format");
			}
			throw new Error(errorData.message || "Failed to fetch properties");
		}
		return await response.json();
	} catch (error) {
		throw error; // Re-throw the error to be caught by useQuery's onError
	}
};
